:root {
  --color-primary: #3845ff;
  --color-primary-slight-dark: #0964ca;
  --color-primary-slight-light: #4c9ef8;
  --color-primary-dark: #000333;
  --color-primary-light: #e9edff;
  --color-accent: #ffd700;
  --color-warn: #d24b4e;
  --color-dark: #4a4e65;
  --color-light-dark: #758190;
  --color-light: #fff;
  --color-dark-rgb: 63, 67, 80;
  --color-accent-rgb: 255, 171, 0;
  --color-secondary-dark: #172b4d;
  --color-secondary-slight-dark: #0065ff;
  --color-gray: #cbd2de;
  --color-gray-light: #f1f3f4;
  --color-primary-opacity: #3845ffe0;
}
