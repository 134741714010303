*,
::after,
::before {
  box-sizing: border-box;
}

* {
  margin: 0;
  padding: 0;
}

html {
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: transparent;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-smooth: never;
}

article,
aside,
figcaption,
figure,
footer,
header,
hgroup,
main,
nav,
section {
  display: block;
}

body {
  margin: 0;
  font-family: Montserrat, Verdana, sans‑serif, serif;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  text-align: left;
  background-color: #fff;
}

dl,
ol,
ul {
  margin-top: 0;
  margin-bottom: 0;
}

ul {
  list-style: none;

  li {
    text-decoration: none;
  }
}

b,
strong {
  font-weight: bolder;
}

img {
  vertical-align: middle;
  border-style: none;
}
