@use "sass:math";

$color-primary: var(--color-primary);
$color-primary-slight-dark: var(--color-primary-slight-dark);
$color-primary-slight-light: var(--color-primary-slight-light);
$color-primary-dark: var(--color-primary-dark);
$color-primary-light: var(--color-primary-light);
$color-accent: var(--color-accent);
$color-warn: var(--color-warn);
$color-dark: var(--color-dark);
$color-light-dark: var(--color-light-dark);
$color-dark-rgb: var(--color-dark-rgb);
$color-light: var(--color-light);
$color-border: rgba(var(--color-dark-rgb), 0.2);

@function mobileToDesktop($from, $to) {
  @return #{clamp(#{$from}px, #{math.div($to, 1440) * 100}vw, #{$to}px)};
}
