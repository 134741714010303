@use "../abstracts/abstracts" as *;

a {
  --outline-color: #{$color-light};

  text-decoration: none;
  background-color: transparent;
  outline: none !important;

  &:focus-visible {
    box-shadow: 0 0 0 1px var(--outline-color);
    border-radius: 2px;
  }
}

.display-none {
  display: none;
}

.sr-only {
  border: 0 !important;
  clip: rect(1px, 1px, 1px, 1px) !important;
  -webkit-clip-path: inset(50%) !important;
  clip-path: inset(50%) !important;
  height: 1px !important;
  margin: -1px !important;
  overflow: hidden !important;
  padding: 0 !important;
  position: absolute !important;
  width: 1px !important;
  white-space: nowrap !important;
}
