@import "https://fonts.googleapis.com/css2?family=Montserrat:wght@500;600;700&display=swap";
*, :after, :before {
  box-sizing: border-box;
}

* {
  margin: 0;
  padding: 0;
}

html {
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: transparent;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-smooth: never;
  line-height: 1.15;
}

article, aside, figcaption, figure, footer, header, hgroup, main, nav, section {
  display: block;
}

body {
  color: #212529;
  text-align: left;
  background-color: #fff;
  margin: 0;
  font-family: Montserrat, Verdana, sans‑serif, serif;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
}

dl, ol, ul {
  margin-top: 0;
  margin-bottom: 0;
}

ul {
  list-style: none;
}

ul li {
  text-decoration: none;
}

b, strong {
  font-weight: bolder;
}

img {
  vertical-align: middle;
  border-style: none;
}

a {
  --outline-color: var(--color-light);
  background-color: #0000;
  text-decoration: none;
  outline: none !important;
}

a:focus-visible {
  box-shadow: 0 0 0 1px var(--outline-color);
  border-radius: 2px;
}

.display-none {
  display: none;
}

.sr-only {
  clip: rect(1px, 1px, 1px, 1px) !important;
  clip-path: inset(50%) !important;
  white-space: nowrap !important;
  border: 0 !important;
  width: 1px !important;
  height: 1px !important;
  margin: -1px !important;
  padding: 0 !important;
  position: absolute !important;
  overflow: hidden !important;
}

:root {
  --color-primary: #3845ff;
  --color-primary-slight-dark: #0964ca;
  --color-primary-slight-light: #4c9ef8;
  --color-primary-dark: #000333;
  --color-primary-light: #e9edff;
  --color-accent: gold;
  --color-warn: #d24b4e;
  --color-dark: #4a4e65;
  --color-light-dark: #758190;
  --color-light: #fff;
  --color-dark-rgb: 63, 67, 80;
  --color-accent-rgb: 255, 171, 0;
  --color-secondary-dark: #172b4d;
  --color-secondary-slight-dark: #0065ff;
  --color-gray: #cbd2de;
  --color-gray-light: #f1f3f4;
  --color-primary-opacity: #3845ffe0;
}

.text-light {
  color: var(--color-light);
}

.flex-btns {
  flex-wrap: wrap;
  justify-content: space-between;
  display: flex;
}

@media (width <= 420px) {
  .flex-btns {
    justify-content: center;
  }
}

.card {
  background-color: var(--color-light);
  border-radius: 24px;
  padding-block: 24px;
  padding-inline: 16px;
  box-shadow: 4px 12px 30px 4px #c0c3d34d;
}

.section-list {
  background-color: var(--color-primary-light);
  color: var(--color-dark);
  border-radius: 20px;
  flex-direction: column;
  row-gap: 10px;
  padding: clamp(16px, 2.77778vw, 40px);
  display: flex;
}

.section-list .section-list-item {
  column-gap: clamp(12px, 1.38889vw, 20px);
  display: flex;
}

.icon-arrow-down, .icon-language {
  aspect-ratio: 1;
  fill: var(--color-primary-light);
  width: 20px;
}

.icon-schema {
  aspect-ratio: 587 / 469;
  width: 100%;
}

.icon-underline {
  --underline-icon-color: var(--color-accent);
  aspect-ratio: 156 / 13;
  fill: none;
  width: 156px;
  display: block;
}

.icon-heartbeat {
  width: 30px;
  height: 24px;
}

.nav {
  align-items: center;
  column-gap: 50px;
  display: flex;
}

.nav ul {
  column-gap: 30px;
  display: flex;
}

.nav ul li {
  width: max-content;
}

.nav ul li a {
  --outline-color: var(--color-primary-light);
  --color: var(--color-primary-light);
  color: var(--color);
  padding: 2px;
}

@media (width >= 800px) {
  .nav ul li a {
    --color: var(--color-dark);
    --outline-color: var(--color-dark);
  }
}

.text-align-center {
  text-align: center;
}

.display-inline {
  display: inline !important;
}

.header, .footer, main {
  --main-padding-inline: 16px;
  padding-inline: var(--main-padding-inline);
}

@media (width >= 600px) {
  .header, .footer, main {
    --main-padding-inline: clamp(16px, 6.66667vw, 96px);
  }
}

.header {
  background: var(--color-light);
  column-gap: 100px;
  height: 104px;
  padding-block-start: 30px;
  display: flex;
  position: sticky;
  top: 0;
}

@media (width >= 800px) {
  .header {
    position: relative;
  }
}

.header .dropdown-btn {
  color: var(--color-primary-light);
}

@media (width >= 800px) {
  .header .dropdown-btn {
    color: var(--color-dark);
  }
}

.header__main {
  flex-grow: 1;
  align-items: center;
  column-gap: 50px;
  display: flex;
}

@media (width >= 800px) {
  .header .nav {
    margin-inline-start: auto;
  }
}

@media (width <= 1200px) {
  .header .dropdown {
    display: none;
  }
}

@media (width <= 1000px) {
  .header .link-btn {
    display: none;
  }
}

@media (width <= 800px) {
  .header .menu-btn, .header .link-btn {
    display: block;
  }

  .header .nav {
    margin-inline-end: auto;
  }

  .header .nav ul {
    flex-direction: column;
    row-gap: 32px;
  }

  .header .nav ul li a {
    font-size: 20px;
  }

  .header .logo {
    text-align: center;
  }

  .header__main {
    background-color: var(--color-dark);
    z-index: 1;
    flex-direction: column;
    align-items: stretch;
    height: 100vh;
    padding-block: 30vh 32px;
    padding-inline: 24px;
    transition: transform .35s ease-out;
    position: fixed;
    inset: 0;
    transform: translateX(100%);
  }

  .header__main[data-visible="true"] {
    transform: translateX(0%);
  }

  .header__main .dropdown {
    order: 1;
    align-self: center;
    margin-block-start: auto;
    margin-inline-start: 0;
    display: flex;
  }

  .header__main .link-btn {
    margin-block-start: 50px;
  }
}

.logo {
  color: var(--color-dark);
  align-items: center;
  column-gap: 8px;
  font-size: 26px;
  font-weight: 600;
  display: flex;
}

.logo-image {
  aspect-ratio: 1;
  width: 30px;
}

.menu-btn {
  all: unset;
  aspect-ratio: 1;
  cursor: pointer;
  z-index: 1000;
  background-image: url("burger.2bbee79a.svg");
  background-repeat: no-repeat;
  width: 32px;
  display: none;
  position: fixed;
  top: 40px;
  right: 40px;
}

.menu-btn[aria-expanded="true"] {
  background-image: url("close.5b1ed22a.svg");
}

.btn {
  background: var(--color-light);
  color: var(--color-dark);
  cursor: pointer;
  border-radius: 4px;
  padding: 12px 25px;
  font-size: 16px;
  font-weight: 600;
  transition: all .2s;
  display: inline-block;
  box-shadow: 0 4px 4px #00000026;
}

.btn:hover {
  color: var(--color-primary);
  background: #fff;
  text-decoration: none;
}

.btn.btn-primary {
  background: var(--color-primary);
  color: var(--color-light);
  border: none;
}

.btn.btn-primary:hover {
  background: var(--color-primary-slight-dark);
}

.link-btn {
  --outline-color: var(--color-primary-slight-light);
  background: var(--color-primary);
  text-align: center;
  color: var(--color-light);
  cursor: pointer;
  border-radius: 8px;
  padding: 12px 24px;
  font-size: 20px;
  font-weight: 600;
}

.link-btn:focus-visible {
  border-radius: 8px;
}

.link-btn.btn-secondary {
  background-color: var(--color-primary-light);
  color: var(--color-primary);
}

.link-btn.btn-primary-outline {
  background: var(--color-light);
  border: 1px solid var(--color-primary);
  color: var(--color-primary);
  box-shadow: none;
}

.link-btn.btn-primary-outline:hover {
  background-color: var(--color-primary-light);
}

.dropdown {
  display: inline-block;
  position: relative;
}

.dropdown .icon-arrow-down {
  transition: transform .3s ease-out;
}

.dropdown[aria-expanded="true"] .dropdown-content {
  display: block;
}

.dropdown[aria-expanded="true"] .icon-arrow-down {
  transform: rotate(180deg);
}

.dropdown-content {
  --padding-inline: 12px;
  padding-block: 8px;
  padding-inline: var(--padding-inline);
  border: 1px solid rgba(var(--color-dark-rgb), .2);
  background-color: var(--color-light);
  border-radius: 4px;
  flex-direction: column;
  margin-block-start: 8px;
  display: none;
  position: absolute;
}

.dropdown-content a {
  color: var(--color-dark);
  margin-inline: calc(var(--padding-inline) * -1);
  padding-inline: var(--padding-inline);
  column-gap: 8px;
  padding-block: 2px;
  display: flex;
}

.dropdown-content a:focus-within, .dropdown-content a:hover {
  box-shadow: none;
  background: var(--color-primary-light);
}

.dropdown-btn {
  all: unset;
  color: var(--color-dark);
  cursor: pointer;
  align-items: center;
  padding: 2px;
  font-size: 16px;
  font-weight: 500;
  transition: color .2s;
  display: flex;
}

.footer .dropdown-btn {
  color: var(--color-dark);
}

.footer .dropdown-btn:hover {
  color: var(--color-primary-dark);
}

.dropdown-btn:hover {
  color: var(--color-primary-dark);
  text-decoration: none;
}

.dropdown-btn:focus-visible {
  box-shadow: 0 0 0 1px var(--color-primary-dark);
  border-radius: 2px;
}

.dropdown-btn .icon-language {
  fill: var(--color-dark);
  margin-inline-end: 10px;
}

.dropdown-btn .icon-arrow-down {
  fill: var(--color-dark);
  margin-inline-start: 6px;
}

.section {
  margin-block-start: clamp(48px, 5.55556vw, 80px);
}

.section h1, .section .text-large {
  font-size: clamp(20px, 2.22222vw, 32px);
}

.section-vehicles {
  margin-block-end: clamp(48px, 8.33333vw, 120px);
  padding: 0 20px;
}

.section-vehicles .vehicles-list {
  justify-content: space-around;
  gap: 10px;
  display: flex;
}

.section-vehicles h1 {
  text-align: center;
}

.section-vehicles h2 {
  margin: 20px 0 10px;
  padding-left: 10px;
  font-size: clamp(18px, 1.66667vw, 24px);
}

.section-vehicles p {
  margin: 10px 0;
}

.section-vehicles .vehicles-image {
  width: 100%;
  margin: 20px auto;
  display: block;
}

@media (width >= 800px) {
  .section-vehicles .vehicles-image {
    width: auto;
    max-height: 400px;
  }
}

.section-vehicles .link-form {
  justify-content: center;
  margin: 30px auto;
  display: flex;
}

.section-promo {
  background: var(--color-light);
  flex-direction: column;
  gap: 24px;
  padding-block-end: 0;
  display: flex;
}

.section-promo .schema-icon {
  min-width: 40%;
}

.section-promo p {
  margin-block-start: 16px;
}

.section-promo picture {
  margin-inline: -16px;
}

@media (width >= 600px) {
  .section-promo picture {
    margin-inline: calc(-1 * clamp(16px, 6.66667vw, 96px));
  }
}

@media (width >= 800px) {
  .section-promo picture {
    margin-inline: 0;
  }
}

.section-promo picture img {
  width: 100%;
}

.section-promo h1, .section-promo p {
  color: var(--color-primary-dark);
  text-align: center;
}

.section-promo .link-btn {
  width: max-content;
  margin-block-start: 24px;
  display: block;
}

@media (width >= 800px) {
  .section-promo {
    flex-direction: row-reverse;
    align-items: center;
  }

  .section-promo h1, .section-promo p {
    text-align: start;
  }

  .section-promo .link-btn {
    margin-inline-start: 0;
  }
}

.slider-container {
  position: relative;
}

.slider-container .slider {
  inset-block-end: 40px;
  justify-content: center;
  column-gap: 20px;
  width: 100%;
  display: none;
  position: absolute;
  bottom: -1rem;
}

.slider-container .slider button {
  all: unset;
  inline-size: 40px;
  background-color: rgba(var(--color-dark-rgb), .3);
  cursor: pointer;
  border-radius: 3px;
  height: 7px;
  transition: background-color .3s ease-out;
  display: block;
}

.slider-container .slider button.active {
  background-color: rgba(var(--color-dark-rgb), .8);
}

.slider-container .slider button:focus-visible {
  box-shadow: 0 0 0 2px var(--color-primary-slight-dark);
}

@media (width >= 600px) {
  .slider-container .slider {
    display: flex;
  }
}

.section-solutions {
  overflow-x: hidden;
}

.section-solutions .section-solutions-content {
  row-gap: 60px;
  display: grid;
}

.section-solutions .section-solutions-content h1 {
  margin-block-end: clamp(4px, .833333vw, 12px);
  padding-block: 0;
  font-size: clamp(18px, 1.66667vw, 24px);
}

.section-solutions .section-solutions-content p {
  color: var(--color-light-dark);
  font-size: 16px;
}

.section-solutions > h1 {
  text-align: center;
  padding-block-end: clamp(24px, 4.16667vw, 60px);
}

.section-solutions p {
  color: var(--color-dark);
  padding-block-end: 24px;
  font-size: 14px;
  font-weight: 500;
}

.section-solutions .section-solution-list {
  flex-direction: column;
  row-gap: 16px;
  margin-block-end: 36px;
  display: flex;
}

.section-solutions .section-solution-item {
  column-gap: 8px;
  display: flex;
}

.section-solutions .section-solution-image {
  width: 100%;
  margin-inline: auto;
  display: block;
}

@media (prefers-reduced-motion: no-preference) {
  .section-solutions {
    scroll-behavior: smooth;
  }
}

@media (width >= 600px) {
  .section-solutions {
    padding-inline: 0;
  }

  .section-solutions .section-solutions-content {
    --active-slider-index: 0;
    transform: translateX(calc(var(--active-slider-index) * -100%));
    grid-auto-columns: 100%;
    grid-auto-flow: column;
    block-size: 100%;
    transition: transform .5s ease-out;
  }

  .section-solutions .section-solution {
    scroll-snap-align: center;
  }

  .section-solutions .section-solution-item {
    font-size: clamp(14px, 1.38889vw, 20px);
  }

  .section-solutions .section-solution-image {
    width: calc(100% - 140px);
  }

  .section-solutions p {
    font-size: clamp(14px, 1.25vw, 18px);
  }
}

@media (width >= 800px) {
  .section-solutions .section-solution {
    grid-template-columns: 6fr 4fr;
    align-items: start;
    column-gap: 60px;
    display: grid;
  }

  .section-solutions .section-solution img {
    order: -1;
    width: 100%;
  }

  .section-solutions .section-solution--mirrored {
    grid-template-columns: 1fr 1fr;
  }
}

.section-problems {
  color: var(--color-dark);
  flex-direction: column;
  row-gap: 50px;
  display: flex;
}

.section-problems h1 {
  text-align: center;
  max-inline-size: 500px;
}

.section-problems .marker {
  letter-spacing: -10px;
  font-size: 20px;
  transform: translateY(-3px);
}

.section-problems .section-list-item {
  font-size: clamp(14px, 1.38889vw, 20px);
}

@media (width >= 600px) {
  .section-problems {
    flex-direction: row;
    justify-content: center;
    align-items: center;
    column-gap: 40px;
  }

  .section-problems h1 {
    text-align: start;
  }
}

.section-management, .section-statistics {
  --grid-template-columns-min-width-sm: repeat(2, 1fr);
}

.section-management .cards, .section-statistics .cards {
  gap: clamp(16px, 1.66667vw, 24px);
  max-width: 1400px;
  margin-inline: auto;
  display: grid;
}

@media (width >= 450px) {
  .section-management .cards, .section-statistics .cards {
    grid-template-columns: var(--grid-template-columns-min-width-sm);
    flex-flow: row;
    justify-content: center;
  }
}

@media (width >= 1000px) {
  .section-management .cards, .section-statistics .cards {
    flex-flow: row;
    grid-template-columns: minmax(0, 1fr);
    grid-auto-columns: minmax(0, 1fr);
    grid-auto-flow: column;
  }
}

.section-management h1 {
  text-align: center;
  margin-block-end: clamp(24px, 3.19444vw, 46px);
  font-size: clamp(20px, 2.22222vw, 32px);
}

.section-management .card {
  flex-flow: column;
  row-gap: 24px;
  display: flex;
}

.section-management .card span {
  text-align: center;
  font-weight: 600;
}

.section-management .card .icon-container {
  aspect-ratio: 1;
  background-color: var(--color-primary-light);
  border-radius: 24px;
  place-items: center;
  inline-size: clamp(60px, 5.55556vw, 80px);
  margin-inline: auto;
  display: grid;
}

.section-management .card .icon-container img {
  aspect-ratio: 1;
  filter: drop-shadow(0 12px 6px #00000026);
  inline-size: clamp(24px, 2.08333vw, 30px);
}

.section-statistics {
  --grid-template-columns-min-width-sm: repeat(2, 200px);
}

.section-statistics h1 {
  text-align: center;
  margin-block-end: clamp(24px, 3.19444vw, 46px);
  font-size: clamp(20px, 2.22222vw, 32px);
}

.section-statistics .card {
  background-color: var(--color-light);
  border-radius: 20px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-inline: auto;
  padding: clamp(16px, 2.22222vw, 32px);
  display: flex;
}

@media (width <= 1000px) {
  .section-statistics .card {
    max-width: 240px;
  }
}

.section-statistics .card img {
  block-size: clamp(30px, 4.44444vw, 64px);
  margin-block-end: clamp(18px, 2.5vw, 36px);
}

.section-statistics .card dl {
  flex-flow: column-reverse;
  align-items: center;
  display: flex;
}

.section-statistics .card dl dd {
  font-size: clamp(24px, 2.5vw, 36px);
  font-weight: 700;
}

.section-statistics .card dl dt {
  color: var(--color-light-dark);
  font-size: clamp(14px, 1.38889vw, 20px);
}

.section-statistics .card dl dt:after {
  content: revert;
}

.section-card {
  margin-inline: -16px;
  padding-block-start: clamp(24px, 5.55556vw, 80px);
  padding-inline: 16px;
}

@media (width >= 450px) {
  .section-card {
    border-radius: clamp(4px, 3.33333vw, 48px);
    margin-inline: 0;
    padding-inline: clamp(16px, 4.86111vw, 70px);
  }
}

.section-card-promo {
  background-color: var(--color-primary-dark);
  color: var(--color-primary-light);
  background-image: url("logo-mask.a931417a.svg");
  background-position: right -5% center;
  background-repeat: no-repeat;
  background-size: clamp(300px, 29.1667vw, 420px);
  flex-flow: wrap;
  justify-content: space-between;
  align-items: center;
  column-gap: 32px;
  padding-block: clamp(40px, 4.86111vw, 70px);
  display: flex;
}

.section-card-promo > * {
  flex-grow: 1;
}

.section-card-promo h1 {
  text-align: center;
  margin-block-end: clamp(16px, 1.66667vw, 24px);
}

.section-card-promo p {
  text-align: center;
  margin-block-end: clamp(40px, 3.33333vw, 48px);
  font-size: clamp(14px, 1.25vw, 18px);
}

.section-card-promo .section-buttons {
  flex-wrap: wrap;
  justify-content: center;
  gap: 24px 32px;
  display: flex;
}

.section-card-promo .section-main {
  width: 100%;
}

@media (width >= 600px) {
  .section-card-promo {
    flex-wrap: nowrap;
  }

  .section-card-promo .section-buttons {
    flex-shrink: 0;
    width: 50%;
  }

  .section-card-promo h1, .section-card-promo p {
    text-align: start;
  }
}

.section-stakeholders {
  background-color: rgba(var(--color-accent-rgb), .15);
  padding-block-end: 0;
}

.section-stakeholders h1 {
  text-align: center;
  margin-block-end: clamp(24px, 4.16667vw, 60px);
}

.section-stakeholders .cards {
  grid-template-areas: "bigCard1"
                       "bigCard2"
                       "smallCard1"
                       "smallCard2";
  gap: clamp(8px, .833333vw, 12px);
  margin-block-end: clamp(24px, 2.77778vw, 40px);
  display: grid;
}

@media (width >= 600px) {
  .section-stakeholders .cards {
    grid-template-areas: "bigCard1 bigCard2"
                         "smallCard1 smallCard2";
  }
}

@media (width >= 1000px) {
  .section-stakeholders .cards {
    grid-template-areas: "bigCard1 bigCard2 smallCard1"
                         "bigCard1 bigCard2 smallCard2";
  }
}

.section-stakeholders .card {
  box-shadow: 0 4px 11px #2b28251a;
}

.section-stakeholders .card h2 {
  margin-block-end: clamp(8px, .694444vw, 10px);
  font-size: 18px;
}

.section-stakeholders .card ul {
  color: var(--color-light-dark);
  list-style: inside;
}

.section-stakeholders .schema-icon {
  width: 100%;
  max-width: 50rem;
  margin-inline: auto;
  display: block;
}

.section-supply {
  color: var(--color-dark);
  flex-flow: column;
  align-items: center;
  display: flex;
}

.section-supply h1 {
  text-align: center;
  margin-block-end: clamp(40px, 6.94444vw, 100px);
  font-size: clamp(24px, 3.61111vw, 52px);
}

.section-supply p {
  text-align: center;
  margin-block-end: 24px;
}

.section-supply .steps {
  flex-flow: column;
  row-gap: 8px;
  inline-size: max-content;
  margin-block-end: clamp(24px, 6.94444vw, 100px);
  margin-inline: auto;
  display: flex;
}

.section-supply .step {
  column-gap: 30px;
  font-size: clamp(14px, 1.66667vw, 24px);
  display: flex;
}

.section-supply .arrow {
  background-image: url("arrow-down-sm.b3d1829a.svg");
  background-position: center;
  background-repeat: no-repeat;
  width: clamp(60px, 5.55556vw, 80px);
  height: 24px;
}

.section-supply .tile-sm {
  aspect-ratio: 1;
  background-color: var(--color-primary-light);
  border-radius: 16px;
  place-items: center;
  inline-size: clamp(60px, 5.55556vw, 80px);
  display: grid;
}

.section-supply .tile-sm img {
  aspect-ratio: 1;
  inline-size: clamp(20px, 2.22222vw, 32px);
}

.section-supply .link-btn {
  font-size: 16px;
}

@media (width >= 800px) {
  .section-supply {
    padding-inline: clamp(32px, 4vw, 172px);
  }

  .section-supply .steps {
    grid-template-columns: auto 1fr auto 1fr auto 1fr auto;
    display: grid;
  }

  .section-supply .step {
    flex-flow: column;
    align-items: center;
    row-gap: clamp(20px, 5.55556vw, 80px);
  }

  .section-supply .arrow {
    background-image: url("arrow-right.87a0d18a.svg");
    background-size: contain;
    height: clamp(60px, 5.55556vw, 80px);
    margin-inline: auto;
  }
}

.section-partners, .section-sponsors {
  flex-flow: column;
  align-items: center;
  display: flex;
}

.section-partners h1, .section-sponsors h1 {
  text-align: center;
  margin-block-end: clamp(24px, 3.19444vw, 46px);
  font-size: clamp(20px, 2.22222vw, 32px);
}

.section-partners ul, .section-sponsors ul {
  flex-flow: wrap;
  justify-content: center;
  align-items: center;
  gap: 40px;
  display: flex;
}

.section-partners ul li, .section-sponsors ul li {
  text-align: center;
}

.section-partners ul li a, .section-sponsors ul li a {
  display: inline-block;
}

.section-partners ul li a:focus-visible, .section-sponsors ul li a:focus-visible {
  --outline-color: var(--color-primary-dark);
}

.section-partners ul li .partner-logo, .section-sponsors ul li .partner-logo {
  max-height: 70px;
}

.section-sponsors ul {
  column-gap: 32px;
}

.section-sponsors ul li img {
  max-width: 100%;
}

.section-needs h1 {
  text-align: center;
  margin-block-end: clamp(24px, 4.16667vw, 60px);
}

.section-needs .link-btn {
  max-inline-size: max-content;
  margin-inline: auto;
  padding: 16px 48px;
  display: block;
}

.section-needs .table {
  grid-template-rows: 60px;
  grid-template-columns: repeat(3, minmax(max-content, 1fr)) repeat(2, minmax(250px, 3fr));
  grid-auto-rows: 96px;
  padding-block-end: 16px;
  display: grid;
  overflow-x: auto;
}

.section-needs .table > :nth-child(10n+1), .section-needs .table > :nth-child(10n+2), .section-needs .table > :nth-child(10n+3), .section-needs .table > :nth-child(10n+4), .section-needs .table > :nth-child(10n+5) {
  background-color: #3046cd08;
}

.section-needs .table-header-cell, .section-needs .table-cell {
  padding-inline: 24px;
}

.section-needs .table-header-cell {
  align-items: center;
  display: flex;
}

.section-needs .table-cell {
  flex-flow: column;
  justify-content: center;
  display: flex;
}

.section-needs .table-header-cell {
  font-size: 14px;
  font-weight: 700;
}

.section-needs .table-cell {
  font-size: 16px;
  font-weight: 500;
}

.section-needs .table-cell-text-accent {
  font-weight: 600;
}

.section-needs .table-cell-text-secondary {
  color: var(--color-light-dark);
}

.section-help h1 {
  text-align: center;
  margin-block-end: clamp(24px, 4.16667vw, 60px);
}

.section-help .cards {
  flex-flow: column;
  justify-content: center;
  align-items: center;
  gap: 24px;
  margin-block-end: clamp(24px, 3.33333vw, 48px);
  display: flex;
}

@media (width >= 1000px) {
  .section-help .cards {
    flex-direction: row;
  }
}

.section-help .card {
  max-inline-size: 420px;
  padding-block: 1rem;
}

.section-help .card-progress-container {
  align-items: baseline;
  column-gap: 1rem;
  margin-block-end: 1rem;
  display: flex;
}

.section-help .card-progress-title-content {
  font-size: 16px;
}

.section-help .card-progress-title-content--accent {
  font-size: 24px;
  font-weight: 700;
}

.section-help .card-progress {
  background-color: var(--color-primary-light);
  border-radius: .5rem;
  flex-grow: 1;
  height: .5rem;
  position: relative;
}

.section-help .card-progress .card-progress-tile {
  --progress: 23%;
  content: "";
  width: var(--progress);
  background-color: var(--color-primary);
  border-radius: .5rem;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
}

.section-help .card-title {
  text-align: start;
  margin-block-end: 8px;
  font-size: 18px;
  font-weight: 600;
}

.section-help .card-description {
  color: var(--color-light-dark);
  margin-block-end: 1rem;
  font-size: 14px;
  font-weight: 500;
}

.section-help .card-description-accent {
  color: var(--color-primary);
  text-align: center;
  margin-block-end: 8px;
  font-size: clamp(22px, 1.66667vw, 24px);
  font-weight: 600;
}

.section-help .link-btn {
  max-inline-size: max-content;
  margin-inline: auto;
  padding: 16px 48px;
  display: block;
}

.section-help .card-image-container {
  margin-bottom: 1rem;
  position: relative;
}

.section-help .card-image-container img {
  max-width: 100%;
  display: block;
}

.section-help .card-image-container .card-image-caption {
  --indent: .5rem;
  background-color: var(--color-light);
  padding-left: var(--indent);
  padding-bottom: calc(var(--indent) * .5);
  border-bottom-left-radius: calc(var(--indent) * 2);
  color: var(--color-primary);
  font-size: 14px;
  font-weight: 700;
  position: absolute;
  top: -.5rem;
  right: 0;
}

.section-donate {
  margin-block-end: clamp(48px, 8.33333vw, 120px);
}

dl dt:after {
  content: ":";
}

.footer {
  background-color: var(--color-primary-light);
  flex-direction: column;
  row-gap: clamp(50px, 5.83333vw, 84px);
  padding-block: clamp(48px, 4.30556vw, 62px) 26px;
  display: flex;
}

.footer .logo {
  color: var(--color-dark);
}

.footer section {
  flex-flow: wrap;
  gap: 24px 50px;
  display: flex;
}

.footer .section-secondary {
  font-size: 14px;
}

.footer .section-secondary > * {
  width: 100%;
}

.footer a {
  color: var(--color-dark);
}

.footer .nav ul {
  flex-flow: wrap;
  row-gap: 24px;
}

.footer .nav ul li {
  width: 100%;
}

.footer .nav ul li a {
  color: var(--color-dark);
}

.footer .dropdown-btn .icon-language, .footer .dropdown-btn .icon-arrow-down {
  fill: var(--color-dark);
}

.footer .dropdown {
  align-self: center;
}

@media (width >= 400px) {
  .footer .nav ul li {
    width: max-content;
  }

  .footer .section-secondary > * {
    width: auto;
  }
}

@media (width >= 1000px) {
  .footer .nav {
    margin-inline-start: auto;
  }
}

[data-tooltip]:before {
  content: attr(data-tooltip);
  opacity: 0;
  z-index: 50000;
  text-align: center;
  border-radius: 10px;
  min-width: 100px;
  padding: 10px;
  transition: all .15s;
  position: absolute;
}

[data-tooltip]:hover:before {
  opacity: 1;
  background: var(--color-secondary-slight-dark);
  color: #fff;
}

/*# sourceMappingURL=our-network.5d55d716.css.map */
