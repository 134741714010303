@use "sass:math";
@use "../abstracts/abstracts" as *;
@use "../base/reset" as *;
@use "../base/base" as *;
@use "../theme/colors" as *;

/*!
 * Bootstrap Reboot v4.3.1 (https://getbootstrap.com/)
 * Copyright 2011-2019 The Bootstrap Authors
 * Copyright 2011-2019 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/master/LICENSE)
 * Forked from Normalize.css, licensed MIT (https://github.com/necolas/normalize.css/blob/master/LICENSE.md)
 */
// TODO: Remove dynamic imports because of the bad impact on critical rendering path
@import url(https://fonts.googleapis.com/css2?family=Montserrat:wght@500;600;700&display=swap);

// region components
.text-light {
  color: $color-light;
}

.flex-btns {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  @media (max-width: 420px) {
    justify-content: center;
  }
}

.card {
  padding-block: 24px;
  padding-inline: 16px;
  border-radius: 24px;
  background-color: $color-light;
  box-shadow: 4px 12px 30px 4px rgba(192, 195, 211, 0.3);
}

.section-list {
  display: flex;
  flex-direction: column;
  row-gap: 10px;
  padding: mobileToDesktop(16, 40);
  background-color: $color-primary-light;
  color: $color-dark;
  border-radius: 20px;

  .section-list-item {
    display: flex;
    column-gap: mobileToDesktop(12, 20);
  }
}

.icon-arrow-down,
.icon-language {
  width: 20px;
  aspect-ratio: 1;
  fill: $color-primary-light;
}

.icon-schema {
  aspect-ratio: 587 / 469;
  width: 100%;
}

.icon-underline {
  --underline-icon-color: #{$color-accent};

  display: block;
  width: 156px;
  aspect-ratio: 156 / 13;
  fill: none;
}

.icon-heartbeat {
  width: 30px;
  height: 24px;
}

.nav {
  display: flex;
  align-items: center;
  column-gap: 50px;

  ul {
    display: flex;
    column-gap: 30px;

    li {
      width: max-content;

      a {
        --outline-color: #{$color-primary-light};
        --color: #{$color-primary-light};

        padding: 2px;
        color: var(--color);

        @media (min-width: 800px) {
          --color: #{$color-dark};
          --outline-color: #{$color-dark};
        }
      }
    }
  }
}

// endregion

// region utilities
.text-align-center {
  text-align: center;
}

.display-inline {
  display: inline !important;
}

// endregion

$header-padding-block-start: 30px;
$header-min-height: 104px;
$responsive-inline-padding: clamp(16px, 12vw, 172px);

.header,
.footer,
main {
  --main-padding-inline: 16px;

  padding-inline: var(--main-padding-inline);

  @media (min-width: 600px) {
    --main-padding-inline: #{mobileToDesktop(16, 96)};
  }
}

.header {
  display: flex;
  height: $header-min-height;
  padding-block-start: $header-padding-block-start;
  background: $color-light;
  column-gap: 100px;
  position: sticky;
  top: 0;
  @media (min-width: 800px) {
    position: relative;
  }
  .dropdown-btn {
    color: $color-primary-light;

    @media (min-width: 800px) {
      color: $color-dark;
    }
  }
}

.header__main {
  display: flex;
  align-items: center;
  column-gap: 50px;
  flex-grow: 1;
}

@media (min-width: 800px) {
  .header {
    .nav {
      margin-inline-start: auto;
    }
  }
}

// TODO: refactor to mobile-first
@media (max-width: 1200px) {
  .header {
    .dropdown {
      display: none;
    }
  }
}

// TODO: refactor to mobile-first
@media (max-width: 1000px) {
  .header {
    .link-btn {
      display: none;
    }
  }
}

// TODO: refactor to mobile-first
@media (max-width: 800px) {
  .header {
    .menu-btn {
      display: block;
    }

    .link-btn {
      display: block;
    }

    .nav {
      margin-inline-end: auto;

      ul {
        flex-direction: column;
        row-gap: 32px;

        li {
          a {
            font-size: 20px;
          }
        }
      }
    }
  }

  .header {
    .logo {
      text-align: center;
    }
  }

  .header__main {
    position: fixed;
    inset: 0 0 0 0;
    padding-inline: 24px;
    padding-block-start: 30vh;
    padding-block-end: 32px;
    height: 100vh;
    flex-direction: column;
    align-items: stretch;
    background-color: $color-dark;
    z-index: 1;

    transform: translateX(100%);
    transition: transform 350ms ease-out;

    &[data-visible="true"] {
      transform: translateX(0%);
    }

    .dropdown {
      display: flex;
      margin-inline-start: 0;
      margin-block-start: auto;
      align-self: center;
      order: 1; // TODO: This is an accessibility issue. Discuss with UX team to fix this.
    }

    .link-btn {
      margin-block-start: 50px;
    }
  }
}

.logo {
  display: flex;
  align-items: center;
  column-gap: 8px;
  font-weight: 600;
  font-size: 26px;
  color: $color-dark;
}

.logo-image {
  width: 30px;
  aspect-ratio: 1;
}

.menu-btn {
  all: unset;
  display: none;
  position: fixed;
  top: 40px;
  right: 40px;
  width: 32px;
  aspect-ratio: 1;
  background-image: url("../../assets/img/burger.svg");
  background-repeat: no-repeat;
  cursor: pointer;
  z-index: 1000;

  &[aria-expanded="true"] {
    background-image: url("../../assets/img/close.svg");
  }
}

.btn {
  display: inline-block;
  background: $color-light;
  border-radius: 4px;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.15);
  padding: 12px 25px;
  transition: 0.2s all;
  font-weight: 600;
  font-size: 16px;
  color: $color-dark;
  cursor: pointer;

  &:hover {
    background: #fff;
    text-decoration: none;
    color: $color-primary;
  }

  &.btn-primary {
    border: none;
    background: $color-primary;
    color: $color-light;

    &:hover {
      background: $color-primary-slight-dark;
    }
  }
}

.link-btn {
  --outline-color: #{$color-primary-slight-light};
  background: $color-primary;
  border-radius: 8px;
  padding: 12px 24px;
  text-align: center;
  font-weight: 600;
  font-size: 20px;
  color: $color-light;
  cursor: pointer;

  &:focus-visible {
    border-radius: 8px;
  }

  &.btn-secondary {
    background-color: $color-primary-light;
    color: $color-primary;
  }

  &.btn-primary-outline {
    background: var(--color-light);
    border: 1px solid $color-primary;
    color: $color-primary;
    box-shadow: none;

    &:hover {
      background-color: $color-primary-light;
    }
  }
}

.dropdown {
  position: relative;
  display: inline-block;

  .icon-arrow-down {
    transition: transform 300ms ease-out;
  }

  &[aria-expanded="true"] {
    .dropdown-content {
      display: block;
    }

    .icon-arrow-down {
      transform: rotate(180deg);
    }
  }
}

.dropdown-content {
  --padding-inline: 12px;

  position: absolute;
  display: none;
  flex-direction: column;
  padding-block: 8px;
  padding-inline: var(--padding-inline);
  margin-block-start: 8px;
  border: 1px solid rgba($color-dark-rgb, 0.2);
  border-radius: 4px;
  background-color: $color-light;

  a {
    color: $color-dark;
    display: flex;
    column-gap: 8px;
    margin-inline: calc(var(--padding-inline) * -1);
    padding-inline: var(--padding-inline);
    padding-block: 2px;

    &:focus-within,
    &:hover {
      box-shadow: none;
      background: $color-primary-light;
    }
  }
}

.dropdown-btn {
  all: unset;
  display: flex;
  align-items: center;
  padding: 2px;
  font-weight: 500;
  font-size: 16px;
  color: $color-dark;
  cursor: pointer;
  transition: 0.2s color;

  .footer & {
    color: $color-dark;

    &:hover {
      color: $color-primary-dark;
    }
  }

  &:hover {
    color: $color-primary-dark;
    text-decoration: none;
  }

  &:focus-visible {
    border-radius: 2px;
    box-shadow: 0 0 0 1px $color-primary-dark;
  }

  .icon-language {
    margin-inline-end: 10px;
    fill: $color-dark;
  }

  .icon-arrow-down {
    margin-inline-start: 6px;
    fill: $color-dark;
  }
}

.section {
  //padding-block: mobileToDesktop(48, 120);
  margin-block-start: mobileToDesktop(48, 80);

  h1,
  .text-large {
    font-size: mobileToDesktop(20, 32);
  }
}

.section-vehicles {
  padding: 0 20px;
  margin-block-end: mobileToDesktop(48, 120);
  .vehicles-list {
    display: flex;
    justify-content: space-around;
    gap: 10px;
  }
  h1 {
    text-align: center;
  }
  h2 {
    margin: 20px 0 10px 0;
    font-size: mobileToDesktop(18, 24);
    padding-left: 10px;
  }
  p {
    margin: 10px 0;
  }
  .vehicles-image {
    width: 100%;
    display: block;
    margin: 20px auto;
    @media (min-width: 800px) {
      max-height: 400px;
      width: auto;
    }
  }
  .link-form {
    margin: 30px auto;
    display: flex;
    justify-content: center;
  }
}

.section-promo {
  display: flex;
  flex-direction: column;
  gap: 24px;
  background: $color-light;
  padding-block-end: 0;

  .schema-icon {
    min-width: 40%;
  }

  p {
    margin-block-start: 16px;
  }

  picture {
    margin-inline: -16px;

    @media (min-width: 600px) {
      margin-inline: calc(#{mobileToDesktop(16, 96)} * -1);
    }

    @media (min-width: 800px) {
      margin-inline: 0;
    }

    img {
      width: 100%;
    }
  }

  h1,
  p {
    color: $color-primary-dark;
    text-align: center;
  }

  .link-btn {
    display: block;
    width: max-content;
    margin-block-start: 24px;
  }
}

@media (min-width: 800px) {
  .section-promo {
    flex-direction: row-reverse; // TODO: accessibility issue
    align-items: center;

    h1,
    p {
      text-align: start;
    }

    .link-btn {
      margin-inline-start: 0;
    }
  }
}

.slider-container {
  position: relative;

  .slider {
    display: none;
    justify-content: center;
    column-gap: 20px;
    position: absolute;
    inset-block-end: 40px;
    width: 100%;
    bottom: -1rem;

    button {
      all: unset;
      display: block;
      inline-size: 40px;
      height: 7px;
      border-radius: 3px;
      background-color: rgba($color-dark-rgb, 0.3);
      cursor: pointer;
      transition: background-color 300ms ease-out;

      &.active {
        background-color: rgba($color-dark-rgb, 0.8);
      }

      &:focus-visible {
        box-shadow: 0 0 0 2px $color-primary-slight-dark;
      }
    }

    @media (min-width: 600px) {
      display: flex;
    }
  }
}

.section-solutions {
  overflow-x: hidden;

  .section-solutions-content {
    display: grid;
    row-gap: 60px;

    h1 {
      font-size: mobileToDesktop(18, 24);
      padding-block: 0;
      margin-block-end: mobileToDesktop(4, 12);
    }

    p {
      font-size: 16px;
      color: $color-light-dark;
    }
  }

  > h1 {
    padding-block-end: mobileToDesktop(24, 60);
    text-align: center;
  }

  p {
    padding-block-end: 24px;
    font-weight: 500;
    font-size: 14px;
    color: $color-dark;
  }

  .section-solution-list {
    display: flex;
    flex-direction: column;
    row-gap: 16px;
    margin-block-end: 36px;
  }

  .section-solution-item {
    display: flex;
    column-gap: 8px;
  }

  .section-solution-image {
    display: block;
    margin-inline: auto;
    width: 100%;
  }

  @media (prefers-reduced-motion: no-preference) {
    scroll-behavior: smooth;
  }

  @media (min-width: 600px) {
    padding-inline: 0;

    .section-solutions-content {
      --active-slider-index: 0;

      block-size: 100%;
      grid-auto-flow: column;
      grid-auto-columns: 100%;
      transform: translateX(calc(var(--active-slider-index) * #{-100%}));
      transition: transform 500ms ease-out;
    }

    .section-solution {
      scroll-snap-align: center;
    }

    .section-solution-item {
      font-size: mobileToDesktop(14, 20);
    }

    .section-solution-image {
      width: calc(100% - 140px);
    }

    p {
      font-size: mobileToDesktop(14, 18);
    }
  }

  @media (min-width: 800px) {
    .section-solution {
      display: grid;
      align-items: start;
      column-gap: 60px;
      grid-template-columns: 6fr 4fr;

      img {
        width: 100%;
        order: -1;
      }
    }

    .section-solution--mirrored {
      grid-template-columns: 1fr 1fr;
    }
  }
}

.section-problems {
  display: flex;
  flex-direction: column;
  row-gap: 50px;
  color: $color-dark;

  h1 {
    text-align: center;
    max-inline-size: 500px;
  }

  .marker {
    letter-spacing: -10px;
    font-size: 20px;
    transform: translateY(-3px);
  }

  .section-list-item {
    font-size: mobileToDesktop(14, 20);
  }

  @media (min-width: 600px) {
    align-items: center;
    flex-direction: row;
    justify-content: center;
    column-gap: 40px;

    h1 {
      text-align: start;
    }
  }
}

.section-management,
.section-statistics {
  --grid-template-columns-min-width-sm: repeat(2, 1fr);

  .cards {
    display: grid;
    gap: mobileToDesktop(16, 24);
    max-width: 1400px;
    margin-inline: auto;

    @media (min-width: 450px) {
      flex-flow: row;
      grid-template-columns: var(--grid-template-columns-min-width-sm);
      justify-content: center;
    }

    @media (min-width: 1000px) {
      flex-flow: row;
      grid-template-columns: minmax(0, 1fr);
      grid-auto-flow: column;
      grid-auto-columns: minmax(0, 1fr);
    }
  }
}

.section-management {
  h1 {
    margin-block-end: mobileToDesktop(24, 46);
    font-size: mobileToDesktop(20, 32);
    text-align: center;
  }

  .card {
    display: flex;
    flex-flow: column nowrap;
    row-gap: 24px;

    span {
      text-align: center;
      font-weight: 600;
    }

    .icon-container {
      display: grid;
      place-items: center;
      border-radius: 24px;
      margin-inline: auto;
      inline-size: mobileToDesktop(60, 80);
      aspect-ratio: 1 / 1;
      background-color: $color-primary-light;

      img {
        inline-size: mobileToDesktop(24, 30);
        aspect-ratio: 1 / 1;
        filter: drop-shadow(0px 12px 6px rgba(0, 0, 0, 0.15));
      }
    }
  }
}

.section-statistics {
  --grid-template-columns-min-width-sm: repeat(2, 200px);

  h1 {
    margin-block-end: mobileToDesktop(24, 46);
    font-size: mobileToDesktop(20, 32);
    text-align: center;
  }

  .card {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin-inline: auto;
    padding: mobileToDesktop(16, 32);
    border-radius: 20px;
    background-color: $color-light;

    @media (max-width: 1000px) {
      max-width: 240px;
    }

    img {
      block-size: mobileToDesktop(30, 64);
      margin-block-end: mobileToDesktop(18, 36);
    }

    dl {
      display: flex;
      flex-flow: column-reverse;
      align-items: center;

      dd {
        font-size: mobileToDesktop(24, 36);
        font-weight: 700;
      }

      dt {
        color: $color-light-dark;
        font-size: mobileToDesktop(14, 20);

        &::after {
          content: revert;
        }
      }
    }
  }
}

.section-card {
  margin-inline: -16px;
  padding-inline: 16px;
  padding-block-start: mobileToDesktop(24, 80);

  @media (min-width: 450px) {
    margin-inline: 0;
    border-radius: mobileToDesktop(4, 48);
    padding-inline: mobileToDesktop(16, 70);
  }
}

.section-card-promo {
  padding-block: mobileToDesktop(40, 70);
  background-color: $color-primary-dark;
  background-image: url("../../assets/img/logo-mask.svg");
  background-position: right -5% center;
  background-size: mobileToDesktop(300, 420);
  background-repeat: no-repeat;

  display: flex;
  flex-flow: row wrap;
  align-items: center;
  justify-content: space-between;
  column-gap: 32px;
  color: $color-primary-light;

  > * {
    flex-grow: 1;
  }

  h1 {
    margin-block-end: mobileToDesktop(16, 24);
    text-align: center;
  }

  p {
    font-size: mobileToDesktop(14, 18);
    margin-block-end: mobileToDesktop(40, 48);
    text-align: center;
  }

  .section-buttons {
    display: flex;
    flex-wrap: wrap;
    column-gap: 32px;
    row-gap: 24px;
    justify-content: center;
  }

  .section-main {
    width: 100%;
  }

  @media (min-width: 600px) {
    flex-wrap: nowrap;

    .section-buttons {
      width: 50%;
      flex-shrink: 0;
    }

    h1,
    p {
      text-align: start;
    }
  }
}

.section-stakeholders {
  padding-block-end: 0;
  background-color: rgba(var(--color-accent-rgb), 0.15);

  h1 {
    text-align: center;
    margin-block-end: mobileToDesktop(24, 60);
  }

  .cards {
    display: grid;
    gap: mobileToDesktop(8, 12);
    grid-template-areas:
      "bigCard1"
      "bigCard2"
      "smallCard1"
      "smallCard2";
    margin-block-end: mobileToDesktop(24, 40);

    @media (min-width: 600px) {
      grid-template-areas:
        "bigCard1 bigCard2"
        "smallCard1 smallCard2";
    }

    @media (min-width: 1000px) {
      grid-template-areas:
        "bigCard1 bigCard2 smallCard1"
        "bigCard1 bigCard2 smallCard2";
    }
  }

  .card {
    h2 {
      font-size: 18px;
      margin-block-end: mobileToDesktop(8, 10);
    }

    ul {
      list-style: inside;
      color: $color-light-dark;
    }

    box-shadow: 0px 4px 11px rgba(43, 40, 37, 0.1);
  }

  .schema-icon {
    display: block;
    width: 100%;
    max-width: 50rem;
    margin-inline: auto;
  }
}

.section-supply {
  display: flex;
  flex-flow: column;
  align-items: center;
  color: $color-dark;

  h1 {
    margin-block-end: mobileToDesktop(40, 100);
    text-align: center;
    font-size: mobileToDesktop(24, 52);
  }

  p {
    text-align: center;
    margin-block-end: 24px;
  }

  .steps {
    display: flex;
    flex-flow: column;
    row-gap: 8px;
    inline-size: max-content;
    margin-inline: auto;
    margin-block-end: mobileToDesktop(24, 100);
  }

  .step {
    display: flex;
    column-gap: 30px;
    font-size: mobileToDesktop(14, 24);
  }

  .arrow {
    height: 24px;
    width: mobileToDesktop(60, 80);
    background-image: url("../../assets/img/arrow-down-sm.svg");
    background-repeat: no-repeat;
    background-position-x: center;
    background-position-y: center;
  }

  .tile-sm {
    inline-size: mobileToDesktop(60, 80);
    aspect-ratio: 1 / 1;
    display: grid;
    place-items: center;
    border-radius: 16px;
    background-color: $color-primary-light;

    img {
      inline-size: mobileToDesktop(20, 32);
      aspect-ratio: 1 / 1;
    }
  }

  .link-btn {
    font-size: 16px;
  }

  @media (min-width: 800px) {
    padding-inline: #{clamp(32px, #{math.div(32, 800) * 100}vw, 172px)};

    .steps {
      display: grid;
      grid-template-columns: auto 1fr auto 1fr auto 1fr auto;
    }

    .step {
      flex-flow: column;
      align-items: center;
      row-gap: mobileToDesktop(20, 80);
    }

    .arrow {
      height: mobileToDesktop(60, 80);
      margin-inline: auto;
      background-image: url("../../assets/img/arrow-right.svg");
      background-size: contain;
    }
  }
}

.section-partners,
.section-sponsors {
  display: flex;
  flex-flow: column;
  align-items: center;
  h1 {
    margin-block-end: mobileToDesktop(24, 46);
    font-size: mobileToDesktop(20, 32);
    text-align: center;
  }
  ul {
    display: flex;
    flex-flow: row wrap;
    align-items: center;
    justify-content: center;
    gap: 40px;

    li {
      text-align: center;

      a {
        display: inline-block;

        &:focus-visible {
          --outline-color: #{$color-primary-dark};
        }
      }
      .partner-logo {
        max-height: 70px;
      }
    }
  }
}

.section-sponsors {
  ul {
    column-gap: 32px;

    li {
      img {
        max-width: 100%;
      }
    }
  }
}

.section-needs {
  h1 {
    text-align: center;
    margin-block-end: mobileToDesktop(24, 60);
  }

  .link-btn {
    display: block;
    max-inline-size: max-content;
    padding: 16px 48px;
    margin-inline: auto;
  }

  .table {
    display: grid;
    grid-template-columns: repeat(3, minmax(max-content, 1fr)) repeat(
        2,
        minmax(250px, 3fr)
      );
    grid-template-rows: 60px;
    grid-auto-rows: 96px;
    padding-block-end: 16px;
    overflow-x: auto;

    & > * {
      &:nth-child(10n + 1),
      &:nth-child(10n + 2),
      &:nth-child(10n + 3),
      &:nth-child(10n + 4),
      &:nth-child(10n + 5) {
        background-color: #3046cd08;
      }
    }
  }

  .table-header-cell,
  .table-cell {
    padding-inline: 24px;
  }

  .table-header-cell {
    display: flex;
    align-items: center;
  }

  .table-cell {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
  }

  .table-header-cell {
    font-weight: 700;
    font-size: 14px;
  }

  .table-cell {
    font-weight: 500;
    font-size: 16px;
  }

  .table-cell-text-accent {
    font-weight: 600;
  }

  .table-cell-text-secondary {
    color: $color-light-dark;
  }
}

.section-help {
  h1 {
    text-align: center;
    margin-block-end: mobileToDesktop(24, 60);
  }

  .cards {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;
    gap: 24px;
    margin-block-end: mobileToDesktop(24, 48);

    @media (min-width: 1000px) {
      flex-direction: row;
    }
  }

  .card {
    max-inline-size: 420px;
    padding-block: 1rem;
  }

  .card-progress-container {
    display: flex;
    column-gap: 1rem;
    align-items: baseline;
    margin-block-end: 1rem;
  }

  .card-progress-title-content {
    font-size: 16px;
  }

  .card-progress-title-content--accent {
    font-weight: 700;
    font-size: 24px;
  }

  .card-progress {
    flex-grow: 1;
    position: relative;
    height: 0.5rem;
    border-radius: 0.5rem;
    background-color: var(--color-primary-light);

    .card-progress-tile {
      --progress: 23%;

      content: "";
      position: absolute;
      border-radius: 0.5rem;
      top: 0;
      bottom: 0;
      left: 0;
      width: var(--progress);
      background-color: var(--color-primary);
    }
  }

  .card-title {
    font-weight: 600;
    font-size: 18px;
    margin-block-end: 8px;
    text-align: start;
  }

  .card-description {
    font-weight: 500;
    font-size: 14px;
    color: $color-light-dark;
    margin-block-end: 1rem;
  }

  .card-description-accent {
    font-weight: 600;
    font-size: mobileToDesktop(22, 24);
    color: $color-primary;
    text-align: center;
    margin-block-end: 8px;
  }

  .link-btn {
    display: block;
    max-inline-size: max-content;
    padding: 16px 48px;
    margin-inline: auto;
  }

  .card-image-container {
    position: relative;
    margin-bottom: 1rem;

    img {
      display: block;
      max-width: 100%;
    }

    .card-image-caption {
      --indent: 0.5rem;

      position: absolute;
      top: -0.5rem;
      right: 0;
      background-color: var(--color-light);
      padding-left: var(--indent);
      padding-bottom: calc(var(--indent) * 0.5);
      border-bottom-left-radius: calc(var(--indent) * 2);
      font-weight: 700;
      font-size: 14px;
      color: var(--color-primary);
    }
  }
}

.section-donate {
  margin-block-end: mobileToDesktop(48, 120);
}

dl {
  dt {
    &::after {
      content: ":";
    }
  }
}

.footer {
  display: flex;
  flex-direction: column;
  row-gap: mobileToDesktop(50, 84);
  padding-block-start: mobileToDesktop(48, 62);
  padding-block-end: 26px;
  background-color: $color-primary-light;

  .logo {
    color: $color-dark;
  }

  section {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    row-gap: 24px;
    column-gap: 50px;
  }

  .section-secondary {
    font-size: 14px;

    > * {
      width: 100%;
    }
  }

  a {
    color: $color-dark;
  }

  .nav {
    ul {
      flex-direction: row;
      flex-wrap: wrap;
      row-gap: 24px;

      li {
        width: 100%;

        a {
          color: $color-dark;
        }
      }
    }
  }

  .dropdown-btn {
    .icon-language,
    .icon-arrow-down {
      fill: $color-dark;
    }
  }

  .dropdown {
    align-self: center;
  }

  @media (min-width: 400px) {
    .nav {
      ul {
        li {
          width: max-content;
        }
      }
    }

    .section-secondary {
      > * {
        width: auto;
      }
    }
  }

  @media (min-width: 1000px) {
    .nav {
      margin-inline-start: auto;
    }
  }
}

[data-tooltip]:before {
  content: attr(data-tooltip);
  position: absolute;
  opacity: 0;
  z-index: 50000;
  transition: all 0.15s ease;
  padding: 10px;
  border-radius: 10px;
  min-width: 100px;
  text-align: center;
}
[data-tooltip]:hover:before {
  opacity: 1;
  background: var(--color-secondary-slight-dark);
  color: white;
}
